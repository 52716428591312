<div class="bg-footer-pattern bg-cover bg-center flex flex-col justify-end content-end" style="height:1200px">
  <div class="container mx-auto text-white">
    <div class="flex flex-col justify-center align-center items-center">
      <img src="/assets/logo/footer.svg" class="mb-14" style="height: 40px;">
      <span class="text-lg sm:text-3xl">Want to contact us?</span>
      <a class="text-sm sm:text-base hover:text-blue" href="mailto:hello@pagonxt.com">hello@pagonxt.com</a>
      <app-vertical-line height="100px" class="my-4"></app-vertical-line>
      <!-- <span class="text-xs">Social</span> -->
      <div class="flex mt-4 mb-16">
        <a class="rrss" href="https://www.linkedin.com/company/pagonxt/mycompany/?viewAsMember=true" target="_blank">
          <img class="normal" src="/assets/rrss/linkedin.svg">
          <img class="hover" src="/assets/rrss/linkedin-blue.svg">
        </a>
        <!-- <a class="rrss" href="https://twitter.com/PagoNxt" target="_blank">
          <img class="ml-5 normal" src="/assets/rrss/twitter.svg">
          <img class="ml-5 hover" src="/assets/rrss/twitter-blue.svg">
        </a> -->
        <a class="rrss" href="https://www.youtube.com/channel/UC06kc2mKO0lGiKzU9oNrjcA" target="_blank">
          <img class="ml-5 normal" src="/assets/rrss/youtube.svg">
          <img class="ml-5 hover" src="/assets/rrss/youtube_blue.svg">
        </a>
        <!-- <a class="rrss" href="https://www.instagram.com/pagonxt" target="_blank">
          <img class="ml-5 normal" src="/assets/rrss/instagram.svg">
          <img class="ml-5 hover" src="/assets/rrss/instagram_blue.svg">
        </a> -->
      </div>
    </div>
    <hr class="mx-4">
    <div class="text-xs flex flex-col sm:flex-col justify-between px-2 sm:px-10 sm:px-0 pb-10 sm:pb-5">
      <div class="py-8    text-center sm:text-right">
        <a target="_blank" href="../../../assets/pdf/Protection_PagoNxt_ENG.pdf" class="font-semibold text-xxs sm:text-xs text-center w-2/6 px-2">Privacy</a>
        <a target="_blank" href="../../../assets/pdf/legal_notices_PagoNxt_ENG.pdf" class="font-semibold text-xxs sm:text-xs text-center w-2/6 px-2">Legal Notice</a>
        <a target="_blank" href="../../../assets/pdf/cookies_PagoNxt_ENG.pdf" class="font-semibold text-xxs sm:text-xs text-center w-2/6 px-2">Cookie Policy</a>
        <a routerLink="open-channel" class="font-semibold text-xxs sm:text-xs text-center w-2/6 px-2">Conduct & Canal Abierto</a>
        <a *ngIf="isJoinUsRoute" target="_blank" href="https://content.globalgetnet.com/pagonxtbr-relatorio-transparencia-e-igualdade-salarial.html" class="font-semibold text-xxs sm:text-xs text-center w-2/6 px-2">(Brasil) Relatório de Transparência</a>
      </div>
      <span class="text-grey text-center sm:text-left text-xxs sm:text-xs self-center">© 2023 PagoNxt S.L. PagoNxt is a registered trademark. All rights reserved.</span>
    </div>
  </div>
</div>
